.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.result {
  font-weight: normal;
  white-space: pre-wrap;
}

.inputfields {
  width:100%;
  max-width:350px;
  margin-bottom:10px;
  padding:10px;
  border:1px solid rgb(207, 217, 222);
}

.toolbox {
  border:1px solid #eeeeee;
  margin-bottom:20px;
  padding:20px;
  padding-top:0px;
}

.main {
  padding:20px;
}

.main_hero {
  margin-bottom:30px;
}

.main_hero_octie {
  margin-right:20px;
}

.hidden {
  display:none!important;
}

.section_center_hero {
  margin-left:auto;
  margin-right:auto;
  max-width:700px;
  text-align:center;
  margin-top:25px;
  padding:20px;
}

.section_center_sub {
  margin-left:auto;
  margin-right:auto;
  max-width:1000px;
  margin-top:100px;
  padding-top:100px;
  padding-bottom:90px;
  padding-left:10px;
  padding-right:10px;
}

.section_center_sub_bg {
  background-image:url('/public/circles.svg');
  background-size:cover;
}

.text-octie-orange {
  color:#fe9d02;
}

.footer-text {
  font-size:12px;
  padding-top:5px;
  padding-bottom:7px;
  padding-left:10px;
  padding-right:10px;
}

.font-weight-light {
  font-weight:300;
}

.stripe_loading {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(0, 0, 0, .90);
  color:white;
  z-index:9999999;
  padding-top:200px;
  text-align:center;
}

.button {
  background:#000;
  border:1px solid #000;
  padding-left:20px;
  padding-right:20px;
  padding-top:10px;
  padding-bottom:10px;
  display:inline-block;
  border-radius:20px;
  color:white;
  font-weight:bold;
  cursor:pointer;
}

.button_second {
  background:#fff;
  border:1px solid #000;
  padding-left:20px;
  padding-right:20px;
  padding-top:10px;
  padding-bottom:10px;
  display:inline-block;
  border-radius:20px;
  color:#3b96ea;
  font-weight:bold;
  cursor:pointer;
}

.home-page-button {
  min-width:200px;
}

.btn_rounded {
  border-radius:20px;
}

.btn_spaced {
  margin-right:10px;
}

.main_content {
  margin-left: auto;
  margin-right:auto;
  max-width:1000px;
}

.main_content_right {
  margin-left:280px;
  margin-top:37px;
}

.logged_out {
  width:100%;
  background-image:url('/public/ripple.svg');
  position:relative;
  overflow:scroll;
}

.logged_out_old {
  width:100%;
  background-image:url('/public/ripple.svg');
  position:fixed;
  top:0px;
  bottom:0px;
  padding-left:10px;
  padding-right:10px;
}

.logged_in {
  width:100%;
}

.unlock {
  width:100%;
  height:100%;
  background-image:url('/public/orange.svg');
  position:relative;
  overflow:scroll;
}

.progress-unlock {
  height:50px;
  font-size:18px;
  font-weight:bold;
}

.progress-progress {
  background-color:#ff9800;
  width:97%;
}

.no_decoration_links {
  text-decoration: none;
  color: #757575;
}

.task-card:hover {
  border: 1px solid rgb(12 109 253);
}


.main_signup {
  max-width:800px;
  margin-left:auto;
  margin-right:auto;
}

.button_top_right {
  float:right;
  font-weight:bold;
}

.save-option {
  cursor:pointer;
  padding:20px;
}

.save-option:hover {
  background-color:#eeeeee;
}

.save-option-selected {
  background-color:#d5e2f5;
}

.clickable {
  cursor:pointer;
}


.quiz_buttons {
  margin-bottom:8px;
}

.bionic-text {
  font-weight:normal;
}

.task-form {
  margin-bottom:20px;
}






#root {
  display:flex;
}


html {
  height: -webkit-fill-available;
}

main {
  display: flex;
  flex-wrap: nowrap;
  top:59px;
  bottom:0px;
  overflow-x: auto;
  overflow-y: hidden;
  position:fixed;
}

.sub_left_nav {
  padding-left:40px;
  cursor:pointer;
}

.sub_left_nav_active {
  background-color:#d5e2f5;
}

.sub_left_nav_notactive:hover {
  background-color:#eeeeee;
}

.main_left_nav:hover {
  background-color:#eeeeee;
}

.icon-blue {
  color:#0d6efd;
}

.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle { outline: 0; }

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold { font-weight: 600; }
.lh-tight { line-height: 1.25; }

.sidebar {
  width:280px;
  border-right:1px solid #eeeeee;
}

.mobile {
  display:none;
}

@media screen and (max-width: 780px) {
  .mobile {
    display:block;
  }
  .sidebar {
    width: 100%;
  }
  .sidebar-mobile {
    display:none!important;
  }
  main {
    z-index:999;
    overflow-y:scroll;
  }
  .main_content_right {
    margin-left:0px;
  }
}